<template>
	<div class="tsp-main">
		<div :class="{textType2:(textType==2),textType1:(textType==1)}"  @click="queryStopOver()">经停站</div>
		<el-dialog
		  title="经停信息"
		  :visible.sync="dialogVisible"
		  style="font-weight: bold;text-align: left;"
		  :class="{marT15:stapBool}"
		  :modal-append-to-body="false"
		>	
			<!-- 携程样式 -->
			<div class="itemContent">
				<span class="itemTitle bold"></span>
				<span class="itemTitle bold">站名</span>
				<span class="itemTitle bold">发车</span>
				<span class="itemTitle bold">到站</span>
				<span class="itemTitle bold">停留</span>
			</div>
			<div class="setDialog">
				<div v-for="(item, index) in trStopOverList" :key="item.stationNo" class="stop" :class="{stopColor:(fromIndex==index || toIndex==index)}">
					<span class="stopItem" style="text-align: center;">
						<span class="itemyx" :class="{stopfrom:fromIndex==index,stopto:toIndex==index,stopyx:(index>=fromIndex && index<=toIndex)}"></span>
						<span class="itemsx" v-if="index!=endIndex" :class="{stopsx:(index>=fromIndex && index<toIndex)}"></span>
					</span>
					<span class="stopItem">{{item.stationName}}</span>
					<span class="stopItem">{{item.startTime}}</span>
					<span class="stopItem">{{item.arriveTime}}</span>
					<span class="stopItem">{{item.stopOverTime}}</span>
				</div>
			</div>
			<!-- 其他样式 -->
			<!-- <div class="itemDiv">
				<span class="itemSpan bold">站次</span>
				<span class="itemSpan bold">站名</span>
				<span class="itemSpan bold">出发时间</span>
				<span class="itemSpan bold">到站时间</span>
				<span class="itemSpan bold">停留时间</span>
			</div>
			<div class="setDialog">
				<div v-for="(item, index) in trStopOverList" :key="item.stationNo"  class="itemDiv" 
					:class="{
							colorStart:fromIndex!=startIndex && index==startIndex,
							colorEnd: toIndex!=endIndex && index==endIndex,
							colorFrom: index==fromIndex || index==toIndex,}">
					<span class="itemSpan">
						<span :class="{ itemForm:index==fromIndex || index==toIndex,
										colorFrom: index>fromIndex && index<toIndex,
										itemStart:fromIndex!=0 && index==startIndex,
										itemEnd: toIndex!=endIndex && index==endIndex}">{{item.stationNo}}</span>
					</span>
					<span class="itemSpan">{{item.stationName}}</span>
					<span class="itemSpan">{{item.startTime}}</span>
					<span class="itemSpan">{{item.arriveTime}}</span>
					<span class="itemSpan">{{item.stopOverTime}}</span>
				</div>
			</div> -->	
		
				
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'TrainStopOver',
		props:{
			// 传入对象信息
			stopOverObj:{
				type:Object,
				default() {
					return {}
				}
			},
			// 填写页与预订页 文样式不同
			textType:{
				type:Number,
				default() {
					return 1
				}
			},
			//填写页或预订页 除外处理dialog 嵌套出现定位位置问题
			stapBool:{
				type:Boolean,
				default: false,
			}
		},
		data() {
			return {
				dialogVisible:false,
				trStopOverList:[], //火车票经停站
				fromIndex: 0, //出发索引
				toIndex:0, //到达索引
				startIndex: 0, //始发站
				endIndex: 0, //重点站
        isXak: this.$route.query.sqdType == 'xak',
			}
		},
		methods: {
			queryStopOver() {
				this.dialogVisible = true;
				if(this.stopOverObj){
          let date,newDate;
          let paramObj = {};
          if(this.isXak){
            date = this.stopOverObj.departDate;
            paramObj = {
            	fromStationCode: this.stopOverObj.departAirport,
            	toStationCode: this.stopOverObj.arriveAirport,
            	trainNo: this.stopOverObj.trainNo,
            	byTrainDate: date,
            	trainCode: this.stopOverObj.flightNo,
            }
          }else{
            date = this.stopOverObj.trainStartDate;
            newDate = (date.slice(0,4))+'-'+(date.slice(4,6))+'-'+(date.slice(6,8));
            paramObj = {
            	fromStationCode: this.stopOverObj.fromStationCode,
            	toStationCode: this.stopOverObj.toStationCode,
            	trainNo: this.stopOverObj.trainNo,
            	byTrainDate: newDate,
            	trainCode: this.stopOverObj.trainCode,
            }
          }
					this.queryStopOverList(paramObj)
				}
			},
			queryStopOverList(paramObj) {
				this.$conn.getConn('trBook.queryStation',{error:true})(paramObj).then((res)=>{
					let data = res.data.data || [];
					this.trStopOverList = data;
					let leng = this.trStopOverList.length-1;
					this.trStopOverList.map((item,index)=>{
						if(item.startTime==this.stopOverObj.startTime && index!=0){
							item.stationNo = '出';
							this.fromIndex = index;
						}else if(item.arriveTime==this.stopOverObj.arriveTime && index!=leng){
							item.stationNo = '到';
							this.toIndex = index;
						}else if(item.startTime==this.stopOverObj.startTime && index==0){
							item.stationNo = '出 (始)';
							this.fromIndex = index;
							this.startIndex = index;
						}else if(item.arriveTime==this.stopOverObj.arriveTime && index==leng){
							item.stationNo = '到 (终)';
							this.toIndex = index;
							this.endIndex = index;
						}else if(item.startTime!=this.stopOverObj.startTime && index==0){
							item.stationNo = '始';
							this.startIndex = index;
						}else if(item.arriveTime!=this.stopOverObj.arriveTime && index==leng){
							item.stationNo = '终';
							this.endIndex = index;
						}
						return item;
					})
				}).catch((e)=>{
					
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.tsp-main{
		font-family: '微软雅黑';
		font-weight: 400;
		color: #424242;
		.marT15{
			margin-top: 15vh;
		}
		/deep/ .el-dialog{
			width: 600px;
			margin-top: 0!important;
			.el-dialog__body{
				padding:10px 20px 30px 20px;
			}
		}
		.itemContent{
			width: 100%;
			line-height: 40px;
			
		}
		.itemTitle{
			width: 20%;
			display: inline-block;
			font-weight: 100;
		}
		.setDialog{
			max-height: 500px;
			overflow-y: auto;
			font-size: 12px;
			
			.stop,.stopIn.stopE{
				width: 100%;
				line-height: 40px;
				position: relative;
				.stopItem{
					width: 20%;
					display: inline-block;
					font-weight: 100;
					position: relative;
					.itemyx{
						display: inline-block;
						width: 9px;
						height: 9px;
						border: 1px solid #ccc;
						box-sizing: border-box;					
						border-radius: 50%;
					}
					.itemsx{
						position:absolute;
						left: 50%;
						top:68%;
						// transform: translate(-1px,0);
						width: 1px;
						height: 26px;
						border-left:1px solid #CCC;	
					}
					.stopfrom,.stopto{
						border: 1px solid #398EFE;
						background-color: #398EFE;
					}
					.stopsx{
						border-left: 1px solid #398EFE;
					}
					.stopyx{
						display: inline-block;
						width: 9px;
						height: 9px;
						box-sizing: border-box;					
						border-radius: 50%;
						border: 1px solid #398EFE;
					}
					
				}
				
			}
			.stopColor{
				color: #398EFE;
			}
		}
		.setDialog::-webkit-scrollbar {
			width: 6px;
		}
		
		.setDialog::-webkit-scrollbar-track {
			background-color: #eef1f6;
		}
		
		.setDialog::-webkit-scrollbar-thumb {
			background-color: #398EFE;
		}
		.colorFrom{
			color: #0086f6;
		}
		.bold{
			font-weight: bold;
		}
		.itemForm,.itemTo{
			color: #FFFFFF;
			background: #0086f6;
			border-radius: 10px;
			width:auto;
			height: 20px;
			display: inline-block;
			line-height: 20px;
			text-align: center;
			padding:0 4px;
		}
		
		.itemStart,.itemEnd{
			color: #FFFFFF;
			background: #FF9024;
			border-radius: 10px;
			width:auto;
			height: 20px;
			display: inline-block;
			line-height: 20px;
			text-align: center;
			padding:0 4px;
		}
		.colorStart{
			color: #FF9024;
		}
		.colorEnd{
			color: #FF9024;
		}
		.el-button{
			width: 70px;
			height: 30px;
		}
		.textType1{
			font-size: 14px;
			font-weight: 100;
			line-height: 30px;
			color:#FF9024;
		}
		.itemDiv{
			width: 100%;
			line-height: 40px;
		}
		.itemSpan{
			width: 20%;
			display: inline-block;
		}
		
		.textType1:hover{
			cursor: pointer;
			opacity: 0.7;
			transition: all 0.3s ease;
		}
		.textType2{
			color:#FFFFFF;
		}
		.textType2:hover{
			cursor: pointer;
		}
	}
</style>
