<template>
	<div class="tr-search">
		<div class="search-item tls-city" >
			<yj-tr-city 
				:disabled="disabled" 
				:isModalStation = "isModalStation"
				:keywords="depKeyword"
				@checkTrCity="startCheckTrCity"  @trChangeRouteRole="trFormChangeRouteRole">
				<template v-slot:content>
					<el-input :disabled="disabled" class="setInput"  placeholder="可输入出发城市"
						v-model="depCityName"
						:readonly="isInputReadonly"
						>
						<template #prefix>
							<el-button type="text" class="iconfont yj-icon-train2huoche btIcon"></el-button>
						</template>
					</el-input>
				</template>
			</yj-tr-city>
			<el-button type="text" class="iconfont yj-icon-iconfonteg  tr-city-qh" :style="{transform: 'rotate('+countRotate+'deg)'}"  @click="switchCity"></el-button>
			<yj-tr-city 
				:disabled="disabled" 
				:keywords="arrKeyword"
				:isModalStation = "isModalStation"
				@checkTrCity="endCheckTrCity"  @trChangeRouteRole="trToChangeRouteRole">
				<template v-slot:content>
					<el-input :disabled="disabled" class="setInput"  placeholder="可输入到达城市"
						v-model="arrCityName"
							:readonly="isInputReadonly"
						>
						<template #prefix>
							<el-button type="text" class="iconfont yj-icon-train2huoche btIcon"></el-button>
						</template>
					</el-input>
				</template>
			</yj-tr-city>
		</div>
		<div class="search-item">
			<el-date-picker  size="large" class="setDate" value-format="yyyy-MM-dd" v-model="depDate"
				type="date" :picker-options="pickerOptions(bookDateRange.minDay,bookDateRange.maxDay,searchData.tripType,searchData.maxDay)" placeholder="选择日期" @change="getChooseDate">
				<template #prefix-icon>
					<el-button type="text" class="btIcon"></el-button>
				</template>
			</el-date-picker>
		</div>
		<div class="search-item">
			<el-button type="primary" class="search-btn" @click="queryTrain" >
				<span v-if="trQueryStatus!=1">搜索您的车次</span>
				<span v-if="trQueryStatus==1"><span class="el-icon-loading"></span>搜索中...</span>
			</el-button>
		</div>
		<yj-train-city-station :visible="trStationVisible" :cityType="cityType" @cancel="cancel" @chooseStation="chooseStation"></yj-train-city-station>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	export default{
		name: 'TrainSearch',
		props:{
			orderChange:{ //改签单搜索
				type: Boolean,
				default:false,
			},
			disabled:{ //此处为新建出差申请单 改签单 选择城市是否可更改
				type: Boolean,
				default: false,
			},

			bookDateRange:{
				type: Object,
				default(){
					return {};
				},
			}
		},
		data() {
			return {
				depKeyword: '', // 输入出发城市
				arrKeyword: '', // 输入到达城市
				
				dateBegin: "", // 处理出差申请单日期控制
				dateEnd: "", // 处理出差申请单日期控制
				rotate:0,
				trStationVisible: false, // 火车站点选择
				cityType: 1, 
				// isModalStation: false, // 是否弹出站点选择
				
			}
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				trainData: state => state.train.trainData, // 火车票数据
				cxrList: state => state.train.cxrList, //当前出行人
				
			}),
			// 登录人信息
			userInfo() {
				let userInfo = this.$common.getUserInfo() || {};
				return userInfo;
			},
			vipCorp() {
				return this.userInfo.vipCorp || {};
			},
			// 1单订模式  2出差申请单模式
			bookStyle() {
				let bookStyle = this.vipCorp.bookStyle;
				return bookStyle
			},
			// 当前搜索状态
			search(){
				return this.searchData.search;
			},
			tripType(){
				return this.searchData.tripType;
			},
			isInputReadonly(){
				let bool = false;
				if(this.bookStyle ==2 && this.tripType == 1 && (this.changeRouteRole == 0 || this.changeRouteRole ==2 || this.changeRouteRole == 3 )){
					bool = true;
				}
				return bool
			},
			// 火车票加载状态
			trQueryStatus(){
				return this.searchData.trQueryStatus;
			},
			//根据出差申请单预订时可更改行程
			changeRouteRole(){
				/*
				*出差申请单模式下，因公订单
				* 0 行程不可修改 ，（站点可选择行程中站点） 
				* 1 可任意更改， （可随意选择行程，就是用火车票组件就行）
				* 2 行程中城市任意组合： （可随意选择行程所有城市）
				* 3 出发到达城市限制可选 （出发就在行程出发城市中选择，到达在到达城市中选择）
				*/
				return this.$common.getVipParamterByParNo(this.$store,10991, 0,'value1',10904);
			},
			//查询出差申请单预订行程日期可否变更
			params10992(){
				return this.$common.getVipParamterByParNo(this.$store,10992, 0);
			},
			//根据出差申请单预订时可更改出行日期
			params10993(){
				return this.$common.getVipParamterByParNo(this.$store,10993, 0);
			},
			// 改签单是否受申请单管控" 0不控制  1控制
			gqgkParams() {
				let params = this.$common.getVipParamterByParNo(this.$store,20024, 0)
				return params
			},
			toDate(){
				let today = this.$dateTime.getToDay();
				return new Date(today)
			},
			// 是否弹出站点选择
			isModalStation(){
				// 默认不弹出 false 使用站点;true使用城市控件
				// 当城市被禁用的时候应该无法触发弹窗！
				// 什么情况下城市选择器无法出来？
				// 因公模式且为出差申请单模式下且changeRouteRole!=1的情况 ，changeRouteRole 为0 2或3的时候才会触发有弹窗的出现！
				let bool = false;
				if(this.searchData.tripType==1 && this.bookStyle==2 && this.changeRouteRole==1){
				    bool = true
				}else if(this.searchData.tripType==1 && this.bookStyle==2 && (this.changeRouteRole==0 || this.changeRouteRole==2 || this.changeRouteRole==3)){
					bool = false
				}else{
				    bool = true
				}
				return bool
			},
			// 出发城市
			depCityName: {
				get() {
					return this.searchData.depInputName; 
				},
				set(val) {
					this.SET_SearchData({
						depInputName: val,
					})
					this.depKeyword = val
				}
			},
			arrCityName: {
				get() {
					return this.searchData.arrInputName;
				},
				set(val) {
					this.SET_SearchData({
						arrInputName: val,
					})
					this.arrKeyword = val
				}
			},
			depDate: { // 出发日期
				get() {
					return this.searchData.depDate;
				},
				set(val) {
					this.SET_SearchData({
						depDate: val,
					})
				}
			},
			startDate(){
				return new Date(this.dateBegin)
			},
			endDate(){
				return new Date(this.dateEnd)
			},
			//计算旋转度数
			countRotate(){
				return this.rotate
			},
			// 是否是第一次查询
			searchNum(){
				return this.searchData.searchNum;
			}
		},
		
		watch:{
			search:{
				handler(bool){
					if(bool){
						this.queryTrain()
					}	
				},
				immediate:true,	
			},
		},
		methods:{
			...mapMutations({
				SET_SearchData: 'train/setSearchData',
				SET_TrainData: 'train/setTrainData',
			}),
			// 日期控制 最小天数 ，最大天数 ， 因公因私， 因私最大天数
			pickerOptions(minDay,maxDay){
				let minTime = this.$common.getStartDateTimeStamp(minDay);
				let maxTime = this.$common.getStartDateTimeStamp(maxDay);
				return {
					//定义不可选择时间 暂无预约功能 查找有效日期
					// 查询出差申请单预订行程日期可否变更 0不可更改  1 按行程更改 2 出差申请单日期范围更改
					disabledDate(time) {
						return time.getTime() < minTime  || time.getTime() > maxTime;
					},
				}
			},
			// 选中出发城市
			startCheckTrCity(val) {
				
				this.SET_SearchData({
					depCity: val.airportName || val.cityName || val.zdmc || '',
					depCitySzm: val.threeCode || val.zddm || '',
					depCityId: val.csbh?val.csbh:val.id || '',
					depInputName: val.cityInput || '',
				})
			},
			// 选中到达城市
			endCheckTrCity(val) {
				
				this.SET_SearchData({
					arrCity: val.airportName || val.cityName || val.zdmc || '',
					arrCitySzm: val.threeCode || val.zddm || '',
					arrCityId: val.csbh?val.csbh:val.id || '',
					arrInputName: val.cityInput || '',
				})
			},
			// 较换城市
			switchCity() {
				if(this.orderChange){
					// 改签单不可切换城市 搜索
					return
				}
				if(this.bookStyle == 2 && this.searchData.tripType == 1){
					if(this.changeRouteRole == 0 || this.changeRouteRole == 3){
						this.$alert('您正处于因公出差模式,无法交换城市！','温馨提示',{
							type: 'warning' 
						})
						return 
					}else{
						if(this.searchData.depCitySzm && this.searchData.arrCitySzm){
							
							let queryObj = {
								arrCity: this.searchData.depCity, //  出发城市
								arrCitySzm: this.searchData.depCitySzm, //  出发城市三字码
								arrCityId:  this.searchData.depCityId,
								arrInputName: this.searchData.depInputName,
								depCity: this.searchData.arrCity,//  到达城市
								depCitySzm: this.searchData.arrCitySzm, //  到达城市三字码
								depCityId: this.searchData.arrCityId, 
								depInputName: this.searchData.arrInputName,
							}
							this.SET_SearchData(queryObj);
						}
						this.getRotate(this.rotate);
					}
				}else{
					if(!this.searchData.depCity || !this.searchData.arrCity){	
						return 
					}
					if(this.searchData.depCitySzm && this.searchData.arrCitySzm){
						
						let queryObj = {
							arrCity: this.searchData.depCity, //  出发城市
							arrCitySzm: this.searchData.depCitySzm, //  出发城市三字码
							arrCityId:  this.searchData.depCityId,
							arrInputName: this.searchData.depInputName,
							depCity: this.searchData.arrCity,//  到达城市
							depCitySzm: this.searchData.arrCitySzm, //  到达城市三字码
							depCityId: this.searchData.arrCityId, 
							depInputName: this.searchData.arrInputName,
						}
						this.SET_SearchData(queryObj);
					}
					this.getRotate(this.rotate);
				}
			},
			// 出发城市站点
			trFormChangeRouteRole(){
				// if(this.changeRouteRole == 0 && (this.searchData.arrCitySzm && this.searchData.depCitySzm) && this.searchData.arrCitySzm != this.searchData.depCitySzm){
				// 	this.$alert('出差申请单预订行程站点不可更改！','温馨提示',{
				// 		type: 'warning' 
				// 	})
				// }else{
					this.trStationVisible = true;
					document.body.style.height = '100vh'
					document.body.style['overflow-y'] = 'hidden'
				// }
				this.cityType = 1;
			},
			// 到达城市站点
			trToChangeRouteRole(){
				// if(this.changeRouteRole == 0 && (this.searchData.arrCitySzm && this.searchData.depCitySzm) && this.searchData.arrCitySzm != this.searchData.depCitySzm){
				// 	this.$alert('出差申请单预订行程站点不可更改！','温馨提示',{
				// 		type: 'warning' 
				// 	})
				// }else{
					document.body.style.height = '100vh'
					document.body.style['overflow-y'] = 'hidden'
					this.trStationVisible = true;
				// }
				this.cityType = 2;
			},
			// 选中城市站点
			chooseStation(obj){
				if(this.cityType == 1){
					this.SET_SearchData(obj);
				}else{
					this.SET_SearchData(obj);
				}
				this.trStationVisible = false;
				document.body.style.height = 'unset'
				document.body.style['overflow-y'] = 'auto'         
			},
			// 取消关闭城市站点
			cancel(bool){
				this.trStationVisible = bool;
				document.body.style.height = 'unset'
				document.body.style['overflow-y'] = 'auto'
			},
			// 获取当前旋转度数
			getRotate(rotate){
				this.rotate = 180 + rotate;
			},
			// 获取选中日期
			getChooseDate(){
				this.$emit('chooseDate',this.depDate)
			},
			// 查询火车票
			queryTrain(){
				
				// 防止重复搜索
				if(this.trQueryStatus==1){
					return false
				}
				//验证信息
				let vailParam = this.vailParam();
				if (vailParam) {
					this.$alert(vailParam,'温馨提示',{
						type: 'error' 
					})
					this.SET_TrainData({
						'trainData': {
							trainList:[],
							feeList: [],
						}
					})
					return false
				}
				// search : true , 其他页面打开弹窗进行一次搜索
				this.SET_SearchData({
					trQueryStatus: 1,
					search: false, 
					allLoading: this.searchNum?false:true,
					reFresh:false,
				});  
				
				this.$emit('queryTr')
				let queryString = {
					budget:this.searchData.fyys, //出差申请单预算金额
					byTrainDate: this.searchData.depDate,
					ccsqdId: (this.bookStyle==1?'':this.searchData.ccsqdId),
					ccsqdRouteId: (this.bookStyle==1?'':this.searchData.routeId) ,
					fromStationCode: this.searchData.depCitySzm,
					minRank: this.searchData.empRank, //差旅标准员工最低职级
					// allTrains: true, 
					toStationCode: this.searchData.arrCitySzm,
					// includePrice: true, //是否包含票价 ，
					tripType: this.searchData.tripType,
				}
				
				
				this.$conn.getConn('trBook.trSearch')(queryString,(res)=>{
					let data = res.data || {};
					this.SET_TrainData({
						trainData: data,
					});
					
					this.$emit('trainListlength', data.trainList.length);
					
					if(data.trainList.length>0){
						this.SET_SearchData({
							trQueryStatus: 2,
							partLoading:true,
							searchNum:1,
							reFresh:true,
						});
					}else{
						this.SET_SearchData({
							trQueryStatus: 3,
							partLoading:true,
							searchNum:1,
							reFresh:true,
						});
					}
					
				
				},(err)=>{
					this.SET_TrainData({
						'trainData': {
							trainList:[],
							feeList: [],
						}
					})
					// this.cfSearch = false;
					this.SET_SearchData({
						trQueryStatus: 4,
						partLoading:false,
						searchNum:0,
						reFresh:true,
						
					});
					// this.$emit('isLoading', 4);
					this.$alert(err.tips,'温馨提示',{
						type: 'error' 
					})
				})
			},
			// 验证参数
			vailParam() {
				let errMsg = "";
				if(this.cxrList.length==0 && this.searchData.tripType==1 && !this.orderChange){
					errMsg = '最少选择一位出行人';
					this.$emit('isLoading', 4);
					return errMsg
				}
				if(this.bookDateRange.noCanTips){
					errMsg = this.bookDateRange.noCanTips;
					return errMsg
				}else if (!this.depDate) {
					errMsg = '出发日期不能为空';
					return errMsg
				} else if(this.depDate){
					let today = this.$dateTime.getToDay();
					let depDate = new Date(this.depDate);
					let todays = new Date(today);
					    if(todays.getTime() > depDate.getTime()){
							errMsg = '出发日期不得小于当前时间';
							return errMsg
					    }
				}
				
				if (!this.searchData.depCitySzm) {
					errMsg = '出发城市不能为空';
					return errMsg
				} else if (!this.searchData.arrCitySzm) {
					errMsg = '到达城市不能为空';
					return errMsg
				}else if(this.searchData.depCitySzm && this.searchData.arrCitySzm && (this.searchData.arrCitySzm == this.searchData.depCitySzm)){
					errMsg = '同城市,相同站点不可预订';
					return errMsg
				}else if (this.searchData.tripType == 1 && this.bookStyle == 2 && !this.orderChange ) {
					if (!this.searchData.ccsqdId) {
						errMsg = '因公模式下请选择出差申请单';
						return errMsg
					}
				}
				return errMsg
			
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tr-search {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		font-family: '微软雅黑';
		.tls-city {
			display: flex;
			justify-content: space-between;
		}
	
		.search-item {
			margin-right: 15px;
			/deep/ .el-input__prefix{
				color: #1C83ED;
				text-align: center;
				width:25px;
			}
			.setInput {
				cursor: pointer;
				/deep/ .el-input__inner {
					height: 45px;
					width: 200px;
					line-height: 45px;
					background: #F9FBFF;
					border: 2px solid #D9E1ED;
					border-radius: 1px;
					font-size: 15px;
					font-weight: 400;
					color: #424242;
				}
				.btIcon{
					line-height: 24px;
					font-size: 16px;
					width:25px;
					text-align: center;
				}
			}
	
			.tr-city-qh {
				font-size: 14px;
				line-height: 2px;
				height: 24px;
				width: 24px;
				border: 2px solid #D9E1ED;
				border-radius: 50%;
				text-align: center;
				cursor: pointer;
				margin: 10px;
				display: inline-block;
				box-sizing: border-box;
				// transition: all 0.3s ease;
			}
			.tr-city-qh:hover{
				transition: all 0.3s ease;
				border: 2px solid #1C83ED;
			}
			
			.setDate {
				width: 200px;
	
				/deep/ .el-input__inner {
					height: 45px;
					width: 200px;
					line-height: 45px;
					background: #F9FBFF;
					border: 2px solid #D9E1ED;
					border-radius: 1px;
				}
			}
	
			.search-btn {
				border-radius: 0;
				height: 45px;
				width: 235px;
				font-size: 15px;
				font-weight: 600;
			}
		}
	
		.pointer {
			cursor: pointer;
		}
	
	
	}
			
</style>
