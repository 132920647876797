<template>
	<el-card>	
		<div class="wd-main">
			<div class="wd-pz-left" @click="prveWeek"><span class="el-icon-arrow-left"></span></div>
			<div class="wd-pz-center">
				<div class="wd-center" :style="getTotalWidth()" >
					<div v-for="item in dateList" :key="item.date" class="wd-date">
						<div v-if="item.isCan == 1" :class="{colorTheme:(depDate==item.date)}"  @click="chooseDate(item.date)">{{item.date.substr(-5,5)}} {{item.week}}</div>
						<div v-if="item.isCan == 2" :class="{colorTheme:(depDate==item.date)}" @click="tsMessage">{{item.date.substr(-5,5)}} {{item.week}}</div>
					</div>
				</div>
			</div>
			<div class="wd-pz-right" @click="nextWeek"><span class="el-icon-arrow-right"></span></div>
		</div>
	</el-card>
	
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapGetters
	} from 'vuex';
	export default{
		name: 'WeekDateCopy',
		props:{
			// 选中日期
			selectDate:{
				type: String,
				default: ""
			},
			// 日期范围
			dateRangeObj:{
				type:Object,
				default(){
					return {};
				}
			}
		},
		data() {
			return {
				dateList:[], //日期列表
				depDate:'', //当前选中出发日期
				newDepDate:'', //点击上下一周获取新日期
				offSet:0, // 0默认 1上一周 2 下一周
				currentIndex:0, //默认不偏移
			}
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
			}),
			// 最大天数
			maxDay(){
				return this.searchData.maxDay
			}
		},
		mounted() {
			this.initSelectDate();
		},
	
		watch: {
			// 侦听传入的搜索日期，与week日期联动
			selectDate: {
				handler(val){
					this.depDate = val;
					this.anchorDate();
				},
				immediate: true   
			},
			dateRangeObj:{
				handler(val){
					this.initSelectDate();
				},
				immediate: true   
			}
		},
		methods: {
			// 获取日期总长度
			getTotalWidth(){
				let obj = {};
				if(this.dateList.length>0){
					let zlength = this.dateList.length * 123;
					let curLength =861 * this.currentIndex;
					obj.width= (this.dateList.length * 123)+'px';
					obj.transform = "translateX(-"+curLength+"px)";
					obj.transition = " all linear 0.5s ";
						
					
				}
				
				return obj
			},
			// 上一周
			prveWeek() {
				let toDay = this.$common.getToDay(); //今天
				let bool = false; //在出发范围内可选择
				let	maxDate = this.$common.getPreStrDate(toDay,(this.maxDay-1)); //最大日期 因私控制

				if(this.currentIndex>0){
					this.currentIndex --;
				}else{
					return false
				}	
				this.getTotalWidth();			
			},
			// 下一周
			nextWeek() {
				let toDay = this.$common.getToDay(); //今日
				let	maxDate = this.$common.getPreStrDate(toDay, (this.maxDay-1)); //最大日期
				let bool = false;			

				if(this.currentIndex<3){
					this.currentIndex ++;
				}else{
					return false
				}
				this.getTotalWidth();	
			},
			// 点击选中日期
			chooseDate(date){
				this.depDate = date;
				this.$emit('chooseDate',date)
			},
			// 指定一周内, 定位到指定日期
			anchorDate(){
				for(var s=0; s<this.dateList.length; s++){
					if(this.dateList[s].date == this.depDate){
						if(s<=6){
							this.currentIndex = 0
						}else if(s>6 && s<=13){
							this.currentIndex = 1
						}else if(s>13 && s<=20){
							this.currentIndex = 2
						}else{
							this.currentIndex = 3
						}
						this.getTotalWidth();
					}
				}
			},
			
			// 初始选中日期
			initSelectDate(date) {
				let toDay = this.$common.getToDay(); //今日 最小日期
				let	maxDate = this.$common.getPreStrDate(toDay,(this.maxDay-1)); //最大日期
				let diffDay = this.$common.getDiffDay(toDay, this.depDate); // 计算今日与用户选择的日期差
				var weekList = [];
				for(var i = 0; i < 28; i++) {
					var day = {};
					var showDay = this.$common.getPreStrDate(toDay, i);
					// 因公 处理一周日期选择
					if(this.searchData.tripType == 1){
						if(showDay <= this.dateRangeObj.maxDay &&  showDay >= this.dateRangeObj.minDay) {
							//获取周
							var weekStr = this.$common.getWeekZhou(showDay);
							day['date'] = showDay;
							day['week'] = weekStr;	
							day['isCan'] = 1;
							weekList.push(day);
						}else{
							var weekStr = this.$common.getWeekZhou(showDay);
							day['date'] = showDay;
							day['week'] = weekStr;
							// 超出最大预订范围不可选择
							day['isCan'] = 2;
							weekList.push(day);
						}
					}else{
					// 因私处理出差范围选择
						if(showDay <= maxDate){
							var weekStr = this.$common.getWeekZhou(showDay);
							day['date'] = showDay;
							day['week'] = weekStr;	
							day['isCan'] = 1;
							weekList.push(day);
						}else{
							var weekStr = this.$common.getWeekZhou(showDay);
							day['date'] = showDay;
							day['week'] = weekStr;
							// 超出最大预订范围不可选择
							day['isCan'] = 2;
							weekList.push(day);
						}
					}
				}
				this.dateList = weekList;
				this.anchorDate();
				
			},
			// 不可选择提示
			tsMessage(){
				if(this.searchData.tripType == 1){
					this.$alert('当前日期不在可选范围之内,可选值为'+this.dateRangeObj.minDay+'至'+this.dateRangeObj.maxDay, '提示', {
					        confirmButtonText: '确定',
							type: 'error',
					    });
				}else{
					let toDay = this.$common.getToDay(); //今日
					let	maxDate = this.$common.getPreStrDate(toDay,(this.searchData.maxDay-1)); //最大日期
					this.$alert('当前日期不在可选范围之内,可选值为'+toDay+'至'+maxDate, '提示', {
					        confirmButtonText: '确定',
							type: 'error',
					    });
				}
				
				
			}
		}
	}
</script>

<style scoped lang="scss">
	/deep/ .el-card__body{
			padding: 0;
			height: 60px;
			line-height: 60px;
		}
	.wd-main{
		display: flex;
		
		align-items: center;
		.wd-pz-right,.wd-pz-left{
			font-size: 30px;
			color: #CCCCCC;
			height:100%;
			width: 38.5px;
		}
		
		.wd-pz-right:hover{
			cursor: pointer;
			color: #4089FF;
			background: rgba(245,245,245,1);
			transition: all 0.4s ease;
		}
		.wd-pz-left:hover{
			cursor: pointer;
			color: #4089FF;
			background: rgba(245,245,245,1);
			transition: all 0.4s ease;
		}
		.wd-pz-center{
			overflow: hidden;
			font-size: 30px;
			color: #CCCCCC;
			width: 861px;
			height:60px;
			.wd-center{
				display: flex;
				flex-wrap: wrap;
				
				.wd-date{
					width:123px;
					height: 60px;
					color: #808080;
					font-size: 14px;
				}
				.wd-date:hover{
					transition: all 0.4s ease;
					cursor: pointer;
					color: #4089FF;
					background: rgba(245,245,245,1);
				}
				.colorTheme{
					color: #4089FF;
					background: rgba(245,245,245,1);
				}
			}
			
		}
		
		
	}
	
</style>
